<template>
  <tr :style="{ backgroundColor: row.taskGenerated ? '#3e4559' : ''}">
    <td width="240">
      <span class="text-info" role="button" @click="openCompanyDetails">
        {{ row.accountNo }} - {{ row.companyName }}
      </span>
    </td>
    <td width="240">
      <div v-if="!isEditable">
        {{ row.storeName }}
      </div>
      <div v-else class="pr-2">
        <dx-util-select-box
          id="store"
          v-model="selectedStore"
          :data-source="row.companyStores"
          :search-enabled="true"
          display-expr="storeName"
          value-expr="storeId"
        />
      </div>
    </td>
    <td width="140">
      <span class="badge" :class="resolveSize(row.monthlyItemVolume)">
        {{ row.monthlyItemVolume }}
      </span>
    </td>
    <td width="240">
      <div v-if="!isEditable" class="d-flex align-items-center">
        <b-avatar
          size="28"
          :src="row.avatar"
          variant="light-primary"
          class="badge-minimal"
        >
          <feather-icon
            v-if="!row.avatar"
            icon="UserIcon"
            size="22"
          />
        </b-avatar>
        <span class="text-warning ml-2 font-weight-bold">
          {{ row.assignToFullName }}
        </span>
      </div>
      <div v-else class="pr-2">
        <dx-util-select-box
          id="store"
          v-model="selectedUser"
          :data-source="companyUsers"
          :search-enabled="true"
          display-expr="fullName"
          value-expr="id"
        />
      </div>
    </td>
    <td v-for="day in selectedDays" :key="day" width="240">
      <task-actions :actions="row.days[day]" :is-editable="isEditable" :day="day" @actions-updated="actionsUpdated" />
    </td>
    <td width="90">
      <div class="d-flex">
        <dx-util-button v-if="!isEditable" icon="icon bi-pencil" type="warning" styling-mode="contained" class="mx-half" @click="editCalendar" />
        <dx-util-button v-else icon="save" type="success" hint="Edit calendar" styling-mode="contained" class="mx-half" @click="saveCalendar" />
        <dx-util-button icon="icon bi-lightning" hint="Generate Task" type="default" styling-mode="contained" @click="generateTask" />
      </div>
    </td>
    <draft-tasks :component-id="draftTasksCompId" :task-data="row" @update-task-calendar="$emit('row-updated')" />
  </tr>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import companyService from '@/http/requests/company/companyService.js'
// import { getCompanySizeList } from '@/enums/companySize.enum.js'
import { Notify } from '@/@robustshell/utils/index.js'
import useTaskManagementState from '../useTaskManagementState.js'

export default {
  components: {
    'task-actions': () => import('./TaskActions.vue'),
    'draft-tasks': () => import('./DraftTasks.vue'),
  },
  props: {
    row: {
      type: Object,
      default: () => {},
    },
    companyUsers: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { selectedDays } = useTaskManagementState()
    return {
      selectedDays,
    }
  },
  data() {
    return {
      isEditable: false,
      stores: [],
      selectedStore: '',
      selectedUser: '',
      actions: ['FBA', 'FBM', 'Receiving', 'Problem', 'Shipment'],
      selectedActions: [],
      workingDays: {},
      draftTasksCompId: '',
      // sizes: getCompanySizeList(),
    }
  },
  mounted() {
    this.selectedStore = this.row.storeId
    this.selectedUser = this.row.assignTo
    this.workingDays = this.row.days
  },
  methods: {
    resolveSize(size) {
      if (size === '0-500') return 'badge-light-success'
      if (size === '501-2000') return 'badge-light-warning'
      if (size === '2001-5000') return 'badge-light-danger'
      if (size === '5001-10000') return 'badge-light-primary'
      if (size === '10000+') return 'badge-light-info'
      return ''
    },
    openCompanyDetails() {
      this.$emit('open-company-details', this.row)
    },
    async generateTask() {
      if (!this.selectedUser) {
        Notify.error('Please assign a user for the tasks.')
        return
      }
      this.draftTasksCompId = uuidv4()
      // const payload = {
      //   selectedIds: [this.row.id],
      // }
      // await companyService.generateTasks(payload)
    },
    editCalendar() {
      this.isEditable = !this.isEditable
      // save mode
    },
    actionsUpdated(e) {
      this.workingDays = { ...this.workingDays, ...e }
    },
    async saveCalendar() {
      const payload = {
        id: parseInt(this.row.id, 10),
        assignTo: this.selectedUser,
        storeId: this.selectedStore,
        companyId: this.row.companyId,
        workingDayTask: this.workingDays,
      }
      await companyService.saveCalendar(payload)
      this.isEditable = false
      this.$emit('row-updated')
      // save
    },
  },
}
</script>

<style lang="scss" scoped>
td {
  padding: 10px;
}

</style>
